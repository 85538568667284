import { qspost } from '@/config/axios';

//获取列表数据
export const getList = data => qspost("request?",data,"getChannelList");

//添加渠道
export const add = data => qspost("request?",data,"addChannel");

//冻结解冻渠道
export const freezeThaw = data => qspost("request?",data,"lockChannel");

//编辑渠道
export const edit = data => qspost("request?", data, "updateChannel");

//删除渠道
export const del = data => qspost("request?", data, "delChannel");