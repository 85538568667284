<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">渠道账号：</span>
        <el-input v-model="search.account" placeholder="请输入渠道账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.status" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option :key="0" label="启用" :value="0"></el-option>
          <el-option :key="1" label="禁用" :value="1"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="
            dialogAdd = true;
            title = '新增渠道';
          ">新增渠道</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="admin_id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="nickname" label="渠道名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="渠道账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="is_count" label="计算裂变用户" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{
              color: scope.row.is_count == '启用' ? '#33C933' : '#F33333',
            }">
            {{ scope.row.is_count }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="fake_num" label="新增扣量" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div>10中扣{{ scope.row.fake_num }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="fake_pay" label="充值扣量" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div>
            {{ scope.row.fake_pay.split(",")[0] }}中扣{{
              scope.row.fake_pay.split(",")[1]
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{
              color: scope.row.status == '启用' ? '#33C933' : '#F33333',
            }">
            {{ scope.row.status }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="
              freezeThaw(
                scope.row,
                scope.row.status == '启用' ? '禁用' : '启用'
              )
            ">{{ scope.row.status == "启用" ? "禁用" : "启用" }}</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row.admin_id)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <!-- 新增渠道 -->
    <el-dialog custom-class="add" :title="title" v-model="dialogAdd" width="550px" top="25vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">渠道昵称：</div>
        <el-input v-model="form.nickname" placeholder="请输入昵称" size="small"></el-input>
      </div>
      <div class="form_box" v-if="title == '新增渠道'">
        <div class="form_title">后台账号：</div>
        <el-input v-model="form.account" placeholder="请输入账号" size="small"></el-input>
      </div>
      <div class="form_box" v-if="title == '新增渠道'">
        <div class="form_title">后台密码：</div>
        <el-input v-model="form.password" placeholder="请输入密码" size="small" show-password></el-input>
      </div>
      <div class="form_box2">
        <div class="form_title">新增人数扣量：</div>
        每10个扣
        <el-input v-model="form.fake_num" placeholder="请正确输入数值" type="number" size="small"></el-input>
        个
      </div>
      <div class="form_box" v-if="title == '新增渠道'">
        <div class="form_title">计算裂变用户：</div>
        <el-switch class="switch" v-model="form.is_count" :active-value="0" :inactive-value="1"></el-switch>
      </div>
      <div class="form_box2">
        <div class="form_title">会员充值扣量：</div>
        每
        <el-input v-model="form.fake_pay1" placeholder="请正确输入数值" type="number" size="small"></el-input>
        个，扣量
        <el-input v-model="form.fake_pay2" placeholder="请正确输入数值" type="number" size="small"></el-input>
        个
      </div>
      <div class="form_box" v-if="title == '新增渠道'">
        <div class="form_title">状态：</div>
        <el-switch class="switch" v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
      </div>
      <div class="form_box">
        <div class="form_title">展示字段：</div>
        <el-checkbox-group v-model="form.see_power" size="small">
          <el-checkbox-button v-for="index in powerArr" :label="index.value" :key="index.label">{{ index.label }}</el-checkbox-button>
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-if="title == '新增渠道'" @click="add">确认新增</el-button>
          <el-button type="primary" v-else @click="edit">确认编辑</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, add, freezeThaw, edit, del } from "@/api/users/channel";
import page from "@/components/page";
export default {
  name: "channel",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        account: "", //账号
        checked: 1, //是否精确搜索
        status: -1, //状态
      },
      dialogAdd: false, // 编辑
      title: "新增渠道",
      form: {
        // 弹窗数据
        nickname: "",
        account: "",
        password: "",
        fake_num: 0,
        is_count: 1,
        fake_pay1: 0,
        fake_pay2: 0,
        status: 0,
        see_power: [],
      },
      powerArr: [
        { value: 1, label: "新增人数" },
        { value: 2, label: "充值金额" },
      ],
      tableData: [], //数据
      row: [], //选中的数据
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "account", val: this.search.account },
        { key: "status", val: this.search.status },
        { key: "checked", val: this.search.checked },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //冻结*解冻
    freezeThaw(row, str) {
      this.row = row;
      this.$confirm(
        "你确定要" + str + "渠道：" + row.account + "吗？",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          freezeThaw([{ key: "admin_id", val: row.admin_id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                console.log(res);
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    add() {
      if (!this.form.status && this.form.status !== 0) {
        this.form.status = -1;
      }
      add([
        { key: "account", val: this.form.account },
        { key: "status", val: this.form.status },
        { key: "password", val: this.form.password },
        { key: "nickname", val: this.form.nickname },
        { key: "person_num", val: this.form.fake_num },
        { key: "is_count", val: this.form.is_count },
        { key: "pay_per_num", val: this.form.fake_pay1 },
        { key: "pay_fake_num", val: this.form.fake_pay2 },
        { key: "see_power", val: this.form.see_power.join(",") || 0 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑前数据填充
    editor(row) {
      this.row = row;
      this.title = `编辑渠道(ID：${row.admin_id})`;
      this.dialogAdd = true;
      this.form.nickname = row.nickname;
      this.form.fake_num = row.fake_num;
      this.form.fake_pay1 = row.fake_pay.split(",")[0];
      this.form.fake_pay2 = row.fake_pay.split(",")[1];
      var arr = [];
      if ((row.see_power & 1) > 0) {
        arr.push(1);
      }
      if ((row.see_power & 2) > 0) {
        arr.push(2);
      }
      this.form.see_power = arr;
    },
    edit() {
      edit([
        { key: "admin_id", val: this.row.admin_id },
        { key: "nickname", val: this.form.nickname },
        { key: "person_num", val: this.form.fake_num },
        { key: "is_count", val: this.form.is_count },
        { key: "pay_per_num", val: this.form.fake_pay1 },
        { key: "pay_fake_num", val: this.form.fake_pay2 },
        { key: "see_power", val: this.form.see_power.join(",") || 0 },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
            this.getList();
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 清空弹窗数据
    closeDialog() {
      this.form.nickname = "";
      this.form.fake_num = 0;
      this.form.fake_pay1 = 0;
      this.form.fake_pay2 = 0;
      this.row = []; //清空选中数据
    },
    del(admin_id) {
      this.$confirm("你确定要删除渠道：" + admin_id + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del([{ key: "admin_id", val: admin_id }])
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.data, type: "success" });
                this.dialogAdd = false;
                this.getList();
              } else {
                this.$message({ message: res.data, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.form_box,
.form_box2 {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box2 >>> .el-input {
  width: 20%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
.form_box >>> .el-checkbox-button__inner {
  border: 1px solid #dcdee0;
  border-radius: 4px;
  margin: 0 3.5px;
  color: #cacbce;
}
.form_box >>> .el-checkbox-button.is-checked .el-checkbox-button__inner {
  color: #409eff;
  background: none;
  box-shadow: none;
  border: 1px solid #409eff;
}
.form_box
  >>> .el-checkbox-button.is-checked:first-child
  .el-checkbox-button__inner {
  border: 1px solid #409eff;
}
</style>
